.centered-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgb(256, 256, 220);
}

.centered-text {
  margin-bottom: 16px;

}