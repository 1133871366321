.about-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 2rem 1rem;
}

.about-container h1 {
    font-size: 2.5rem;
    color: #1a1a1a;
    margin-bottom: 2rem;
    text-align: center;
}

.about-content section {
    margin-bottom: 3rem;
}

.about-content h2 {
    font-size: 1.8rem;
    color: #2a2a2a;
    margin-bottom: 1.5rem;
    border-bottom: 2px solid rgba(59, 130, 246, 0.2);
    padding-bottom: 0.5rem;
}

.bio-section p {
    font-size: 1.1rem;
    line-height: 1.6;
    color: #444;
}

.expertise-section ul {
    list-style: none;
    padding: 0;
}

.expertise-section li {
    font-size: 1.1rem;
    margin-bottom: 1rem;
    padding-left: 1.5rem;
    position: relative;
}

.expertise-section li::before {
    content: "•";
    color: rgb(59, 130, 246);
    position: absolute;
    left: 0;
}

.experience-item, .education-item {
    margin-bottom: 2rem;
}

.experience-item h3, .education-item h3 {
    font-size: 1.3rem;
    color: #2a2a2a;
    margin-bottom: 0.5rem;
}

.experience-period {
    font-style: italic;
    color: #666;
    margin-bottom: 0.5rem;
}

.contact-links {
    list-style: none;
    padding: 0;
}

.contact-links li {
    font-size: 1.1rem;
    margin-bottom: 0.5rem;
    color: #444;
}

@media (max-width: 768px) {
    .about-container {
        padding: 1rem;
    }

    .about-container h1 {
        font-size: 2rem;
    }

    .about-content h2 {
        font-size: 1.5rem;
    }

    .bio-section p,
    .expertise-section li,
    .contact-links li {
        font-size: 1rem;
    }
} 