body {
  margin: 0;
  font-family: "Palatino Linotype", "Book Antiqua", Palatino, serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "Palatino Linotype", "Book Antiqua", Palatino, serif;
}

#root {
  width: 80%;
}