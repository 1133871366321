/* App.css */
/* Reset default styles */
body {
    margin: 0;
    min-height: 100vh;
    font-family: "Palatino Linotype", "Book Antiqua", Palatino, serif;
}

#root {
    width: 100%;
    min-height: 100vh;
}

/* Blog-specific styles */
.blog-layout {
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: rgb(256, 256, 220);
    color: #0f172a;
}

.blog-layout .navbar {
    width: 100%;
    background: rgba(255, 255, 220, 0.8);
    backdrop-filter: blur(8px);
    border-bottom: 1px solid rgba(148, 163, 184, 0.2);
    display: flex;
    align-items: center;
    justify-content: center;
    height: 4rem;
    position: sticky;
    top: 0;
    z-index: 1000;
    box-shadow: 0 2px 4px rgba(148, 163, 184, 0.05);
}

.blog-layout .menu-button {
    display: none;
    background: none;
    border: none;
    color: rgb(51, 51, 51);
    cursor: pointer;
    padding: 0.5rem;
    position: absolute;
    right: 1rem;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1001;
}

.blog-layout .menu-button:hover {
    color: rgb(0, 0, 0);
}

.blog-layout .nav-content {
    max-width: 1200px;
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 2rem;
    padding: 0 2rem;
}

.blog-layout .link {
    position: relative;
    display: flex;
    align-items: center;
    padding: 0.5rem 1rem;
    color: rgb(51, 51, 51);
    font-weight: 500;
    text-decoration: none;
    transition: all 0.2s ease;
    font-size: 0.9375rem;
    border-radius: 8px;
    letter-spacing: 0.01em;
}

.blog-layout .link:hover {
    color: rgb(51, 51, 51);
    background: linear-gradient(to bottom right, 
      rgba(51, 51, 51, 0.05), 
      rgba(51, 51, 51, 0.1)
    );
    transform: translateY(-1px);
}

.blog-layout .link::after {
    content: '';
    position: absolute;
    bottom: -1px;
    left: 50%;
    width: 0;
    height: 2px;
    background: linear-gradient(to right, rgb(51, 51, 51), rgb(51, 51, 51));
    transition: all 0.3s ease;
    transform: translateX(-50%);
    border-radius: 2px;
}

.blog-layout .link:hover::after {
    width: 80%;
}

.blog-layout .link span {
    position: relative;
    z-index: 1;
    margin-left: 0.5rem;
}

/* Active link styles */
.blog-layout .link.active {
    color: rgb(51, 51, 51);
    background: linear-gradient(to bottom right, 
      rgba(59, 130, 246, 0.1), 
      rgba(59, 130, 246, 0.15)
    );
}

.blog-layout .link.active::after {
    width: 80%;
}

/* Content area styles */
.blog-layout .content {
    max-width: 70%;
    width: 100%;
    padding: 2rem;
    background: rgba(255, 255, 215);
    backdrop-filter: blur(8px);
    border-radius: 16px;
    margin-top: 2rem;
    border: 1px solid rgba(148, 163, 184, 0.2);
    box-shadow: 
      0 4px 6px -1px rgba(148, 163, 184, 0.05),
      0 2px 4px -1px rgba(148, 163, 184, 0.05);
    box-sizing: border-box;
}

/* Add decorative elements */
.blog-layout .navbar::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background: linear-gradient(to right,
      transparent,
      rgba(59, 130, 246, 0.3),
      transparent
    );
}

/* Optional: Add subtle animation for link icons */
.blog-layout .link svg {
    width: 16px;
    height: 16px;
    opacity: 0.7;
    transition: transform 0.2s ease;
}

.blog-layout .link:hover svg {
    transform: translateY(-1px);
    opacity: 1;
}

/* Mobile-friendly adjustments */
@media (max-width: 768px) {
    .blog-layout .menu-button {
        display: block;
    }

    .blog-layout .navbar {
        height: auto;
        min-height: 4rem;
        padding: 0;
        position: relative;
    }

    .blog-layout .nav-content {
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        flex-direction: column;
        gap: 0;
        padding: 0;
        background: rgba(255, 255, 220, 0.95);
        backdrop-filter: blur(8px);
        transform: translateY(-100%);
        opacity: 0;
        visibility: hidden;
        transition: all 0.3s ease;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    }

    .blog-layout .nav-content.show {
        transform: translateY(0);
        opacity: 1;
        visibility: visible;
    }

    .blog-layout .link {
        width: 100%;
        padding: 1rem 2rem;
        border-radius: 0;
    }

    .blog-layout .link::after {
        display: none;
    }

    .blog-layout .content {
        max-width: 90%;
        margin-top: 1rem;
        padding: 1rem;
    }
}

@media (max-width: 480px) {
    .blog-layout .content {
        max-width: 95%;
        padding: 0.75rem;
    }

    .blog-layout .link {
        padding: 0.75rem 1.5rem;
        font-size: 0.875rem;
    }
}

/* For very small screens */
@media (max-width: 320px) {
    .blog-layout .link {
        font-size: 0.875rem;
    }

    .blog-layout .link svg {
        width: 14px;
        height: 14px;
    }
}