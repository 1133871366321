.header{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: rgb(84, 84, 171);
    font-size: 2rem;
  }
   
  .Posts {
    display: flex;
    flex-direction: column;
    text-align: center;
    width:100%;
    height:100%;
  }
  
  .PostHeader{
    font-size: 2rem;
  }
  
  .Post{
    border: 1px solid;
  }

.posts-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 2rem 1rem;
}

.posts-container h1 {
    font-size: 2.5rem;
    color: #1a1a1a;
    margin-bottom: 2rem;
    text-align: center;
}

.posts-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 2rem;
}

.post-card {
    text-decoration: none;
    color: inherit;
    background: #fff;
    border-radius: 12px;
    overflow: hidden;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease, box-shadow 0.2s ease;
    border: 1px solid rgba(0, 0, 0, 0.1);
}

.post-card:hover {
    transform: translateY(-4px);
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.15);
}

.post-card article {
    padding: 1.5rem;
}

.post-card h2 {
    font-size: 1.5rem;
    color: #2a2a2a;
    margin-bottom: 1rem;
    line-height: 1.3;
}

.post-description {
    font-size: 1rem;
    color: #666;
    margin-bottom: 1.5rem;
    line-height: 1.5;
}

.post-meta {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 0.875rem;
    color: #888;
}

.post-date, .post-read-time {
    display: flex;
    align-items: center;
}

@media (max-width: 768px) {
    .posts-container {
        padding: 1rem;
    }

    .posts-container h1 {
        font-size: 2rem;
    }

    .posts-grid {
        grid-template-columns: 1fr;
        gap: 1.5rem;
    }

    .post-card article {
        padding: 1.25rem;
    }

    .post-card h2 {
        font-size: 1.25rem;
    }
}